import { useEffect, useState } from 'react'
import { Breadcrumb, Button, Alert } from 'react-bootstrap'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import useAuth from 'hooks/useAuth'
import { useRefresher } from 'hooks/useRefresher'
import { useSettings } from 'hooks/useSettings'
import TicketForm from './ticket.form.component'

const Tickets = () => {
	const { user, currentCompany } = useAuth()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [showForm, setShowForm] = useState(false)
	const refresher = useRefresher()

	useEffect(() => {
		/*
		setLoading(true)
		TicketService.findAll(
			currentCompany.company.environmentId,
			currentCompany.company.id,
			user.accessToken,
		)
			.then((response) => {
				console.log(response)
			})
			.catch((error) => {
				setError(error)
			})
			.finally(() => {
				setLoading(false)
			})*/
		setLoading(false)
	}, [currentCompany, user])

	const HeaderComponent = () => {
		const { settings } = useSettings()
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Tickets</Breadcrumb.Item>
				</Breadcrumb>
				{!settings.ticketDisableCreate && (
					<Button
						style={{ float: 'right' }}
						onClick={() => {
							setShowForm(true)
						}}
					>
						Create New Support Ticket
					</Button>
				)}
			</div>
		)
	}

	return (
		<>
			{error ? (
				<Alert dismissible variant="danger" onClose={() => setError(null)}>
					{error.toString()}
				</Alert>
			) : null}
			<HeaderComponent />
			{loading ? (
				<LoadingParagraphs cant={5} animation="glow" />
			) : (
				<>Ticket List Coming Soon!</>
			)}
			<TicketForm
				show={showForm}
				onClose={() => setShowForm(false)}
				onOperationCompleted={refresher.refresh}
			/>
		</>
	)
}
export default Tickets
