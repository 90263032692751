import useAuth, { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useEpicorInvoiceService } from 'services/epicor'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import InvoicesTable from './invoices.table.component'
import InvoicePaymentModal from './invoice.payment.component'

const InvoicesList = () => {
	const { currentCompany } = useAuth()
	const [invoices, setInvoices] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [currentInvoice, setCurrentInvoice] = useState(null)
	const customerId = useCustomerId()

	const EpicorInvoiceService = useEpicorInvoiceService()

	const fetchInvoices = useCallback(async () => {
		setLoading(true)
		try {
			/** Replace with proper attribute here */
			const response = await EpicorInvoiceService.findAll(customerId)
			setInvoices(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorInvoiceService, customerId])

	const printInvoice = async (invoiceNum) => {
		setError(null)
		try {
			const response = await EpicorInvoiceService.print(customerId, invoiceNum)
			const byteCharacters = atob(response.data)
			const byteNumbers = new Array(byteCharacters.length)
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			const file = new Blob([byteArray], { type: 'application/pdf;base64' })
			const fileURL = URL.createObjectURL(file)
			//window.open(fileURL);
			var link = document.createElement('a')
			link.download = `${currentCompany.company.company1}_Invoice_${invoiceNum}.pdf`
			link.href = fileURL
			link.click()
			link.remove()
		} catch (error) {
			setError(error.message)
		}
	}

	useEffect(() => {
		if (customerId !== undefined) {
			fetchInvoices()
		}
	}, [customerId, fetchInvoices])

	return (
		<div style={containerItem}>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<InvoicesTable
					invoices={invoices}
					onPrintInvoice={printInvoice}
					onPayInvoice={setCurrentInvoice}
				/>
			)}
			{currentInvoice && (
				<InvoicePaymentModal
					customerId={customerId}
					invoice={currentInvoice}
					onClose={() => {
						setCurrentInvoice(null)
					}}
				/>
			)}
		</div>
	)
}

export default InvoicesList
