import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useUserDataService } from '../../services/user.service'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import UsersTable from './users.table.component'

const UsersList = ({ refresher, isClient, onEditUser }) => {
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [doFetch, setDoFetch] = useState(true)

	const UserDataService = useUserDataService()

	const fetchUsers = useCallback(async () => {
		if (!UserDataService) {
			return
		}
		setLoading(true)
		try {
			const response = await UserDataService.getAll()
			setUsers(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
			setDoFetch(false)
		}
	}, [UserDataService])

	useEffect(() => {
		if (doFetch || refresher.id) {
			fetchUsers()
		}
	}, [doFetch, fetchUsers, refresher.id])

	return (
		<div style={containerItem}>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={7} />
			) : (
				<UsersTable
					isClient={isClient}
					users={users}
					onUserDeleted={() => {
						setDoFetch(true)
					}}
					onEditUser={onEditUser}
				/>
			)}
		</div>
	)
}

export default UsersList
