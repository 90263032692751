const zxcvbn = require('zxcvbn')

function checkPasswordComplexity(password) {
	const minLength = 8
	const hasUpperCase = /[A-Z]/.test(password)
	const hasLowerCase = /[a-z]/.test(password)
	const hasNumbers = /[0-9]/.test(password)
	const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>`~+=/_\-[\]\\]/.test(
		password,
	)

	if (password.length < minLength) {
		return {
			isValid: false,
			message: 'Password must be at least 8 characters long.',
		}
	}

	if (!hasUpperCase) {
		return {
			isValid: false,
			message: 'Password must contain at least one uppercase letter.',
		}
	}

	if (!hasLowerCase) {
		return {
			isValid: false,
			message: 'Password must contain at least one lowercase letter.',
		}
	}

	if (!hasNumbers) {
		return {
			isValid: false,
			message: 'Password must contain at least one number.',
		}
	}

	if (!hasSpecialCharacters) {
		return {
			isValid: false,
			message: 'Password must contain at least one special character.',
		}
	}

	const zxcvbnResult = zxcvbn(password)
	if (zxcvbnResult.score < 3) {
		return {
			isValid: false,
			message: 'Password is too weak. Try adding more unique characters.',
		}
	}

	return {
		isValid: true,
		message: 'Password is strong.',
	}
}

// Export the function
module.exports = { checkPasswordComplexity }
