import { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'

const TicketForm = ({ show = false, onClose }) => {
	const { user, currentCompany } = useAuth()
	const [isScriptLoaded, setIsScriptLoaded] = useState(false)
	const [form, setForm] = useState(
		/** @type {HTMLElement | undefined} */ (undefined),
	)

	// Ensure the HubSpot script is loaded.
	useEffect(() => {
		const markLoaded = () => {
			setIsScriptLoaded(true)
		}

		if (document.querySelector(`#${scriptId}`)) {
			// The script was already loaded previously.
			markLoaded()
			return
		}

		const script = document.createElement('script')
		script.id = scriptId
		script.src = '//js.hsforms.net/forms/embed/v2.js'
		script.defer = true
		script.addEventListener('load', markLoaded)
		document.head.append(script)

		return () => {
			script.removeEventListener('load', markLoaded)
		}
	}, [])

	// Pre-populate the user's information.
	useEffect(() => {
		const url = new URL(window.location.href)

		if (show) {
			if (user.firstName) {
				url.searchParams.set('firstname', user.firstName)
			}
			if (user.lastName) {
				url.searchParams.set('lastname', user.lastName)
			}
			if (user.email) {
				url.searchParams.set('email', user.email)
			}
			if (currentCompany?.company?.name) {
				url.searchParams.set('company', currentCompany.company.name)
			}
		} else {
			url.searchParams.delete('firstname')
			url.searchParams.delete('lastname')
			url.searchParams.delete('email')
			url.searchParams.delete('company')
		}

		window.history.replaceState(undefined, '', url.href)
	}, [currentCompany, show, user])

	// Load the form.
	useEffect(() => {
		if (show && isScriptLoaded) {
			window.hbspt.forms.create({
				portalId: '44369678',
				formId: 'a939a144-e802-4d3f-9e75-f9c678821f75',
				target: `#${formId}`,
				onFormReady: setForm,
			})
		}
		if (!show) {
			setForm(undefined)
		}
	}, [show, isScriptLoaded])

	return (
		<Modal
			size="lg"
			show={show}
			onHide={onClose}
			animation
			contentClassName="p-3"
		>
			<Modal.Header closeButton>
				<Modal.Title>Create Ticket</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!isScriptLoaded || !form ? <DelayedSpinner /> : null}
				<div id={formId}></div>
			</Modal.Body>
		</Modal>
	)
}

const DelayedSpinner = () => {
	const [isVisible, setIsVisible] = useState(false)
	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsVisible(true)
		}, 500)
		return () => {
			clearTimeout(timeout)
		}
	})
	return isVisible ? (
		<div className="d-flex justify-content-center">
			<Spinner />
		</div>
	) : undefined
}

const scriptId = 'hubspotScript'
const formId = 'hubspotForm'

export default TicketForm
