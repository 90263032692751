const TableHead = ({ headerGroups }) => {
	return (
		<thead>
			{headerGroups.map((headerGroup) => {
				const { key: headerGroupKey, ...headerGroupProps } =
					headerGroup.getHeaderGroupProps()
				return (
					<tr key={headerGroupKey} {...headerGroupProps}>
						{headerGroup.headers.map((column) => {
							const { key: headerKey, ...headerProps } = column.getHeaderProps(
								column.getSortByToggleProps(),
							)
							const isRightJustified = /Amount|Balance|Date|Price/.test(
								column.id,
							)
							return (
								<th
									key={headerKey}
									{...headerProps}
									className={isRightJustified ? 'text-end' : undefined}
								>
									{column.render('Header')}
									<span>
										{!column.isSorted ? '' : column.isSortedDesc ? ' ▼' : ' ▲'}
									</span>
								</th>
							)
						})}
					</tr>
				)
			})}
		</thead>
	)
}

export default TableHead
