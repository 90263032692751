// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { useSession } from 'hooks/useSession'

class UserDataService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 * @param {number} environmentId
	 */
	constructor(http, environmentId) {
		this.#http = http
		this.#environmentId = environmentId
	}

	#http
	#environmentId

	getAll() {
		return this.#http.get(`/api/environments/${this.#environmentId}/users`)
	}

	/**
	 * @param {number} companyId
	 */
	async getUsersByCompanyId(companyId) {
		const resp = await this.#http.get(
			`/api/environments/${this.#environmentId}/users?companyId=${companyId}`,
		)
		return resp.data
	}

	async getAllCompanies() {
		const resp = await this.#http.get(
			`/api/environments/${this.#environmentId}/company`,
		)
		return resp.data
	}

	/**
	 * @param {string} roleType
	 */
	async getRolesByType(roleType) {
		const resp = await this.#http.get(
			`/api/environments/${this.#environmentId}/users/roles/${roleType}`,
		)
		return resp.data
	}

	/**
	 * @param {number} id
	 */
	get(id) {
		return this.#http.get(
			`/api/environments/${this.#environmentId}/users/${id}`,
		)
	}

	/**
	 * @param {unknown} data
	 */
	invite(data) {
		return this.#http.post(
			`/api/environments/${this.#environmentId}/users/invite`,
			data,
		)
	}

	/**
	 * @param {number} id
	 * @param {unknown} data
	 */
	update(id, data) {
		return this.#http.put(
			`/api/environments/${this.#environmentId}/users/${id}`,
			data,
		)
	}

	/**
	 * @param {number} id
	 */
	delete(id) {
		return this.#http.delete(
			`/api/environments/${this.#environmentId}/users/${id}`,
		)
	}

	/**
	 * @param {string} email
	 */
	findByEmail(email) {
		return this.#http.get(
			`/api/environments/${this.#environmentId}/users?email=${email}`,
		)
	}
}

export function useUserDataService() {
	const http = useHttp()
	const { currentCompany } = useSession()
	return useMemo(() => {
		if (currentCompany) {
			return new UserDataService(http, currentCompany.company.environmentId)
		} else {
			return null
		}
	}, [currentCompany, http])
}
