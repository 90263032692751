import { Dropdown, Badge } from 'react-bootstrap'
import { formatCurrency, formatDate } from 'components/utilities'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = () => [
	{
		Header: 'Invoice List',
		columns: [
			{
				Header: 'Invoice#',
				accessor: 'invoiceNum',
			},
			{
				Header: 'Invoice Status',
				accessor: (item) =>
					item.openInvoice ? (
						item.pendingPayment ? (
							<Badge bg="warning">Pending Payment</Badge>
						) : (
							<Badge bg="success">Open</Badge>
						)
					) : (
						''
					),
			},
			{
				Header: 'Credit Memo',
				accessor: (item) => (item.creditMemo ? <Badge>CM</Badge> : ''),
			},
			{
				Header: 'PO#',
				accessor: 'pONum',
			},
			{
				Header: 'Due Date',
				accessor: (item) =>
					item.dueDate != null ? formatDate(item.dueDate) : '',
			},
			{
				Header: 'Invoice Amount',
				accessor: (item) =>
					formatCurrency(item.invoiceAmt, item.currencyCodeCurrencyID),
			},
			{
				Header: 'Invoice Balance',
				accessor: (item) =>
					formatCurrency(item.invoiceBal, item.currencyCodeCurrencyID),
			},
			{
				Header: '⚡',
				accessor: (item) => item,
				Cell: ({ value: item, onPrintInvoice, onPayInvoice }) => (
					<Dropdown>
						<Dropdown.Toggle
							as={CustomToggle}
							id="dropdown-custom-components"
						/>
						<Dropdown.Menu as={CustomMenu}>
							<Dropdown.Item
								eventKey="1"
								onClick={() => {
									onPrintInvoice(item.invoiceNum)
								}}
							>
								Download Invoice
							</Dropdown.Item>
							{item.openInvoice &&
								item.invoiceBal > 0 &&
								!item.pendingPayment &&
								item.stripeEnabled && (
									<Dropdown.Item
										eventKey="2"
										onClick={() => {
											onPayInvoice(item)
										}}
									>
										Pay Invoice
									</Dropdown.Item>
								)}
						</Dropdown.Menu>
					</Dropdown>
				),
			},
		],
	},
]
