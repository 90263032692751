import { Modal } from 'react-bootstrap'
import NewHelpDesk from './new-helpdesk/new.helpdesk.component'

const HelpDeskForm = ({ show = false, onClose, onOperationCompleted }) => {
	const notifyCreated = (data) => {
		onOperationCompleted?.(
			`A new Case has been added. Case number: ${data.hdCaseNum}`,
		)
		onClose?.()
	}

	return (
		<Modal size={'xl'} show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Create Case</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<NewHelpDesk onSubmitted={notifyCreated} />
			</Modal.Body>
		</Modal>
	)
}

export default HelpDeskForm
