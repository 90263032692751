import useAuth, { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useEpicorOrderService } from 'services/epicor/'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import OrdersTable from './orders.table.component'

const OrdersList = ({ refresher }) => {
	const { currentCompany } = useAuth()
	const EpicorOrderService = useEpicorOrderService()
	const [orders, setOrders] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const fetchOrders = useCallback(async () => {
		setLoading(true)
		try {
			/** Replace with proper attribute here */
			const response = await EpicorOrderService.findAll(customerId)
			setOrders(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorOrderService, customerId])

	const printOrder = async (orderNum) => {
		setError(null)

		try {
			const response = await EpicorOrderService.print(customerId, orderNum)
			const byteCharacters = atob(response.data)
			const byteNumbers = new Array(byteCharacters.length)
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			const file = new Blob([byteArray], { type: 'application/pdf;base64' })
			const fileURL = URL.createObjectURL(file)
			var link = document.createElement('a')
			link.download = `${currentCompany.company.company1}_Order_${orderNum}.pdf`
			link.href = fileURL
			link.click()
			link.remove()
		} catch (error) {
			setError(error.message)
		}
	}

	useEffect(() => {
		if (customerId !== undefined) {
			fetchOrders()
		}
	}, [customerId, fetchOrders, refresher.id])

	return (
		<div style={containerItem}>
			{error ? (
				<Alert dismissible variant="danger">
					{error}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<OrdersTable orders={orders} onPrintOrder={printOrder} />
			)}
		</div>
	)
}

export default OrdersList
