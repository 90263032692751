import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import { useRefresher } from 'hooks/useRefresher'
import { useSettings } from 'hooks/useSettings'
import HelpDeskForm from './helpdesk.form.component'
import HelpDeskList from './helpdesks.list.component'

const HelpDesk = () => {
	const [showForm, setShowForm] = useState(false)
	const refresher = useRefresher()

	const HeaderComponent = () => {
		const { settings } = useSettings()
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Cases</Breadcrumb.Item>
				</Breadcrumb>
				{!settings.helpdeskDisableCreate && (
					<Button
						style={{ float: 'right' }}
						onClick={() => {
							setShowForm(true)
						}}
					>
						Create New Case
					</Button>
				)}
			</div>
		)
	}

	return (
		<>
			<HeaderComponent />
			<HelpDeskList refresher={refresher} />
			<HelpDeskForm
				show={showForm}
				onClose={() => setShowForm(false)}
				onOperationCompleted={refresher.refresh}
			/>
			<refresher.Toaster />
		</>
	)
}

export default HelpDesk
