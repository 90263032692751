// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorSharedService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} customerId
	 * @param {string} service
	 */
	findAll(customerId, service) {
		return this.#http.get(`/api/customers/${customerId}/${service}`)
	}

	/**
	 * @param {unknown} data
	 * @param {number} environmentId
	 * @param {number} companyId
	 * @param {number} customerId
	 * @param {string} service
	 */
	create(data, environmentId, companyId, customerId, service) {
		return this.#http.post(`/api/customers/${customerId}/${service}`, {
			data,
			environmentId,
			companyId,
		})
	}

	/**
	 * @param {number} companyId
	 * @param {number} environmentId
	 */
	shipViaList(companyId, environmentId) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/ship-via`,
		)
	}

	/**
	 * @param {number} environmentId
	 * @param {number} companyId
	 * @param {string} partNum
	 */
	partSearch(environmentId, companyId, partNum) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/part?partNum=${partNum}`,
		)
	}

	/**
	 * @param {number} companyId
	 * @param {number} environmentId
	 * @param {string} partNum
	 */
	partInfo(companyId, environmentId, partNum) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/part/${partNum}`,
		)
	}

	/**
	 * @param {number} customerId
	 * @param {string} shipToNum
	 * @param {string} part
	 * @param {number} qty
	 */
	itemPriceByQty(customerId, shipToNum, part, qty) {
		return this.#http.post(`/api/customers/${customerId}/orders/item-price`, {
			part,
			qty,
			shipToNum,
		})
	}
}

export function useEpicorSharedService() {
	const http = useHttp()
	return useMemo(() => new EpicorSharedService(http), [http])
}
