import useAuth, { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useEpicorShipmentService } from 'services/epicor'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import ShipmentsTable from './shipments.table.component'

const ShipmentsList = () => {
	const { currentCompany } = useAuth()
	const EpicorShipmentService = useEpicorShipmentService()
	const [shipments, setShipments] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const fetchShipments = useCallback(async () => {
		setLoading(true)
		try {
			const response = await EpicorShipmentService.findAll(customerId)
			setShipments(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorShipmentService, customerId])

	const printShipment = async (packNum) => {
		setError(null)
		try {
			const response = await EpicorShipmentService.print(customerId, packNum)
			const byteCharacters = atob(response.data)
			const byteNumbers = new Array(byteCharacters.length)
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			const file = new Blob([byteArray], { type: 'application/pdf;base64' })
			const fileURL = URL.createObjectURL(file)
			//TODO: Convert Bytes to PDF and download without "link"
			//window.open(fileURL);
			var link = document.createElement('a')
			link.download = `${currentCompany.company.company1}_Shipment_${packNum}.pdf`
			link.href = fileURL
			link.click()
			link.remove()
		} catch (error) {
			setError(error.message)
		}
	}

	const printBOL = async (packNum, bolNum) => {
		setError(null)
		try {
			const response = await EpicorShipmentService.printBOL(
				customerId,
				packNum,
				bolNum,
			)
			const byteCharacters = atob(response.data)
			const byteNumbers = new Array(byteCharacters.length)
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			const file = new Blob([byteArray], { type: 'application/pdf;base64' })
			const fileURL = URL.createObjectURL(file)
			//TODO: Convert Bytes to PDF and download without "link"
			//window.open(fileURL);
			var link = document.createElement('a')
			link.download = `${currentCompany.company.company1}_BOL_${bolNum}.pdf`
			link.href = fileURL
			link.click()
			link.remove()
		} catch (error) {
			setError(error.message)
		}
	}

	useEffect(() => {
		if (customerId !== undefined) {
			fetchShipments()
		}
	}, [customerId, fetchShipments])

	return (
		<div style={containerItem}>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<ShipmentsTable
					shipments={shipments}
					onPrintShipment={printShipment}
					onPrintBOL={printBOL}
				/>
			)}
		</div>
	)
}

export default ShipmentsList
