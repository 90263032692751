import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { AuthProvider, RequireAuth } from 'hooks/useAuth'
import { ImpersonationProvider } from 'hooks/useImpersonation'
import { SessionProvider } from 'hooks/useSession'
import { SettingsProvider } from 'hooks/useSettings'
import UsersList from './components/users'
import Login from './components/login/login.component'
import Home from './components/home/home.component'
import Settings from './components/settings/settings.component'
import Tickets from './components/tickets/ticket.component'
import ResetPassword from './components/login/reset-password.component'
import NewPassword from './components/login/new-password.component'
import Main from './components/main.component'
import './App.css'
import EpicorConnections from './components/epicor'
import Orders from './components/orders'
import Quotes from './components/quotes'
import Invoices from './components/invoices'
import Shipments from './components/shipments'
import Queries from './components/queries'
import RMAs from './components/rmas'
import HelpDesks from './components/helpdesks'

export default function App() {
	return (
		<ImpersonationProvider>
			<SessionProvider>
				<AuthProvider>
					<BrowserRouter>
						<Routes>
							<Route path="/login" element={<Login />} />
							<Route path="/reset-password" element={<ResetPassword />} />
							<Route path="/signin/:key" element={<NewPassword />} />
							<Route
								path="/"
								element={
									<RequireAuth>
										<SettingsProvider>
											<Main />
										</SettingsProvider>
									</RequireAuth>
								}
							>
								<Route path="home" element={<Home />} />
								<Route path="users" element={<UsersList />} />
								<Route path="settings" element={<Settings />} />
								<Route path="tickets" element={<Tickets />} />
								<Route
									path="epicor-connection"
									element={<EpicorConnections />}
								/>
								<Route path="orders" element={<Orders />} />
								<Route path="quotes" element={<Quotes />} />
								<Route path="invoices" element={<Invoices />} />
								<Route path="shipments" element={<Shipments />} />
								<Route path="rmas" element={<RMAs />} />
								<Route path="helpdesks" element={<HelpDesks />} />
								<Route path="queries" element={<Queries />} />
								{/* 👇️ only match this when no other routes match */}
								<Route path="*" element={<Login />} />
							</Route>
						</Routes>
					</BrowserRouter>
				</AuthProvider>
			</SessionProvider>
		</ImpersonationProvider>
	)
}
